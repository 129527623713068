import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";
import DataTable from "react-data-table-component";
import { useFetch } from "../../../hooks/useFecth";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

const Locations = () => {
    const endpoint = "companies/locations";
    const api = useFetch();
    const parent = "Company";
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const [t] = useTranslation("global");
    const prefix = "location"; 
    const title = t('locationComponent.locations');

    const [data, setData] = useState([]);
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        country: [],
        company: []
    });

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])


    const columns = [
        {
            id: 'location_name',
            name: t('name'),
            selector: row => row.location_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'company_barbers',
            name: t('users'),
            selector: row => row.company_barbers,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'company_barbers',
            name: t('userBarbersComponent.team'),
            selector: row => row.company_barbers,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'country_name',
            name: t('country'),
            selector: row => row.country_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'state_name',
            name: t('state'),
            selector: row => row.state_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'city_name',
            name: t('city'),
            selector: row => row.city_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_created_at',
            name: t('Date create'),
            selector: row => row.location_created_at,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.location_active) },
            selector: row => row.location_active === 1 ? "Active" : "Inactive",
            sortable: true,
            width: "120px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'action',
            name: t("actions"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];


    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    company: handlerApp.getFiltersFromData(response.data,'company_name'),
                    country: handlerApp.getFiltersFromData(response.data,'country_name')
                });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filteredItems = data?.filter(
        item => item.location_id && item.location_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.location_name.toLowerCase().includes(filterText.toLowerCase())
            || item.manager.toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.country_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.location_address?.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{t('locationComponent.locationDescription')}</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            <section className="filters">
                <h4>
                    {t('locationComponent.listLocation')}
                    <span className="counter-data-table">{data.length} {(data.length) ? t('locationComponent.location')+"s" : t('locationComponent.location') }</span>
                </h4>
                <div className="d-flex justify-content-end">
                    <div className="d-flex col-md-3">
                        <div className="input-icon input-search bg-transparent">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("searchIn") +" "+ data.length + " " + t('locationComponent.locations')}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                        <div className="col-md-3 d-flex justify-content-end">
                            <div className="btn-table-actions" onClick={''}>
                                <button className="btn btn-primary-yellow-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('search')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
            />
        </div>
    )
}

export default Locations;