import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const BoldSelectValidated = ({title, name, options,onChange=null, value,dependency=null, register, errors, required=false})=>{
    const [val,setVal] = useState("");
    const [t] = useTranslation("global");
    

    useEffect(()=>{
        if(value){
            if(options.length > 0){
                setVal(value);
            }
        }
    })
    const handleChange = (e)=>{
        // console.log(e);
        setVal(e.target.value);
        if(onChange!= null){
            onChange(e,name,dependency);
        }
    }

    return (
        <FormControl className="w-100 bold-select-form-control" variant="outlined" size="small">
            <InputLabel id={`${title}-label`}>{title}</InputLabel>
            <Select labelId={`${title}-label`} className="bold-select"
                 {...register(name,{ required: required})}
                value={val} 
                onChange={handleChange} 
                label={title} 
                error={errors[name]!= null}
            >
                <MenuItem value="">
                    <em>...</em>
                </MenuItem>
                {
                    options?.length > 0 &&
                    options.map(item=>(<MenuItem value={item.code} key={item.code}>{item.name}</MenuItem>))
                }
            </Select>
            {errors[name]!= null && <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">{
                errors[name]?.type === 'required' ? t("errors.required") : ""
                }</FormHelperText>}
        </FormControl>
    )
}

export default BoldSelectValidated;