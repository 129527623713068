import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
import { Checkbox, TextField } from '@mui/material';
import Navbar from './Navbar';
import "./Navbar.css"



const Notificaciones = () => {
    const endpoint = "customer";
    const [t, i18n] = useTranslation("global");
    const prefix = "category";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();
    const [formData, setFormData] = useState({

        usernotification_rescheduling_email: false,
        usernotification_rescheduling_sms: false,
        usernotification_rescheduling_push : false,
        usernotification_cancel_email: false,
        usernotification_cancel_sms: false,
        usernotification_cancel_push : false,
        usernotification_barber_reminder_email: false,
        usernotification_barber_reminder_sms: false,
        usernotification_barber_reminder_push : false,
        usernotification_barber_reminder: 24,
        usernotification_first_customer_reminder_email: false,
        usernotification_first_customer_reminder_sms: false,
        usernotification_first_customer_reminder_push : false,
        usernotification_first_customer_reminder: 24,
        usernotification_second_customer_reminder_sms : false,
        usernotification_second_customer_reminder_email : false,
        usernotification_second_customer_reminder_push : false,
        usernotification_second_customer_reminder: 24,
        usernotification_arrival_sms : false,
        usernotification_arrival_email : false,
        usernotification_arrival_push : false, 
        usernotification_missed_appoiment_sms : false,
        usernotification_missed_appoiment_email : false,
        usernotification_missed_appoiment_push : false,

        usernotification_stock_sms : false,
        usernotification_stock_email : false,
        usernotification_stock_push : false,

        usernotification_walkin_sms : false,
        usernotification_walkin_email : false,
        usernotification_walkin_push : false
    });

    const { id} = useParams();
    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const back = () => {
        nav(-1);
    }

    const handleChange = () => {
    }


    return (

        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">View Client</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
                <Navbar />
                <div className="row np-row">
                    {/* Columna izquierda */}
                    <div className="col-md-6 mb-2 prueba">
                        <div className="sub-container mb-4">
                            <h4>{t('notifications.rescheduling')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_rescheduling_email}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_rescheduling_sms}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.cancellations')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_cancel_email}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_cancel_sms}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.appointmentAvailabilitySuggestions')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.reminderForBarber')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_barber_reminder_email}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_barber_reminder_sms}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_barber_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_barber_reminder} />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.firstClientReminder')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_first_customer_reminder_email}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_first_customer_reminder_sms}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_first_customer_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_first_customer_reminder} />
                            </div>
                        </div>
                    </div>

                    {/* Columna derecha */}
                    <div className="col-md-6 mb-2 prueba-2">
                        <div className="sub-container mb-4">
                            <h4>{t('notifications.secondClientReminder')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_second_customer_reminder_email}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_second_customer_reminder_sms}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                            <div className="input-group">
                                <TextField className="text-check" label="Hours" variant="outlined" size="small" type="number" />
                                <TextField className="text-check" label="Minutes" variant="outlined" size="small" type="number" />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.lateArrival')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.missedAppointment')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_missed_appoiment_email}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={formData.usernotification_missed_appoiment_sms}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4>{t('notifications.readyBarber')}</h4>
                            <div className="checkbox-group">
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    checked={""}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{t('phone')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-section-view row">
                <span className="divisor"></span>
                <div className="col-md-6">
                    <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                </div>
                <div className="col-md-6">
                    <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                </div>
                <div className="col-md-6">
                    {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                </div>
                <div className="col-md-6">
                    {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                </div>
            </div>
        </div>
    );
}

export default Notificaciones;