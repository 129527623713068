import { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useFetch } from "../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import DragAndDrop from "../../../../../utils/DragAndDrop";
import NavbarEdit from "../../../Customers/NavbarEdit";
import HandlerApp from "../../../../../utils/handlerApp";
import { AppContext } from "../../../../../context/AppContext";
import { LocationContext } from "../../../../../context/LocationContext";


const WalkIn = () => {
    const endpoint = "team/lineup/walkin";
    const [t, i18n] = useTranslation("global");

    const api = useFetch();
    const handlerApp = HandlerApp();
    const [title, setTitle] = useState(t('lineUp.walkin-online'));
    const nav = useNavigate();

    // Permisos
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);

    const [data, setData] = useState([]);
    const { location } = useContext(LocationContext);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    useEffect(() => {
        if (location === "") {
            if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                handlerApp.showAlertLocation();
                setData([]);
            }
        } else {
            sendRequest();
        }
    }, [location])

    const handleCancel = (e) => {
        e.preventDefault();
        nav("/team/usersbarber");
    }

    // TODO: AJUSTAR CUANDO DANIEL TERMINE DE CUSTOMIZAR EL NAV GENERICO

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + '/' + location)
            .then(response => {
                if (response.success) {
                    let d = response.data;
                    setData(d);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = () => {
        console.log(data);
        handlerApp.setLoader(true);
        api.put(endpoint + '/' + location, data,)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/team/users/walkin");
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    return (

        <div className="app container">

            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>

            {/* TODO:CAMBIAR AL NAVBAR QUE CREO DANIEL  */}

            <div className="menu-contenedor ms-2">
                <nav>
                    <ul className="main-nav">
                        <li className="main-item active">
                            <NavLink exact to="/team/users/walkin">{t("lineUp.walkin")}</NavLink>
                        </li>
                        <li className="main-item">
                            <NavLink exact to="/team/users/online">{t("lineUp.online")}</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>


            <div className="container bold-container-content">


                <DragAndDrop
                    data={data}
                    setData={setData}
                    keyString="userbarber_id"
                    name="person_fullname"
                    description="barberlevel_name"
                    image="user_photo"
                    status="userbarber_walkin_restriction"
                    keyOrder="userbarber_order_walkin"
                />

                <div className="container btn-footer-right">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary-yellow-1" onClick={handleUpdateForm}>
                            {t("save")}
                        </button>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default WalkIn;