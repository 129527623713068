import { useTranslation } from "react-i18next";
import HeaderViewScreen from "./HeaderViewScreen";
import RoundImage from "../../componentes/RoundImage";
import { useEffect, useState } from "react";
import HandlerApp from "../../../../utils/handlerApp";
import { useFetch } from "../../../../hooks/useFecth";
import { useParams } from "react-router-dom";
import InfoClient from "../../componentes/InfoClient";


const NewCustomersView = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const { id }        = useParams();

    const endpoint  = "customers";
    const title     = t("customers.viewClient")

    const actions = [
        {name: t("customers.actionView.edit"), endpoint: `/${endpoint}/create`, icon: "fa-pen"},
        {name: t("customers.actionView.createNote"), endpoint: `/${endpoint}/categories/create`, icon: "fa-note-sticky"},
        {name: t("customers.actionView.messageClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-message"},
        {name: t("customers.actionView.callClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-phone"},
        {name: t("customers.actionView.createGiftcard"), endpoint: `/${endpoint}/categories/create`, icon: "fa-gift-card"},
        {name: t("customers.actionView.createAppointment"), endpoint: `/${endpoint}/categories/create`, icon: "fa-calendar-plus"},
        {name: t("customers.actionView.createSale"), endpoint: `/${endpoint}/categories/create`, icon: "fa-cash-register"},
        {name: t("customers.actionView.addFamily"), endpoint: `/${endpoint}/categories/create`, icon: "fa-user-plus"},
        {name: t("customers.actionView.delete"), endpoint: `/${endpoint}/categories/create`, icon: "fa-trash", red: true},
        {name: t("customers.actionView.blockClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-ban", red: true},
    ];

    const [data, setData] = useState([]);
    const [family, setFamily] = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    const sendRequest = ()=>{
        api.get(endpoint + "/" + id)
        .then(response => {
            if (response.success) {
                handlerApp.setLoader(false);
                let data = response.data[0];
                setFamily(data.family);
                console.log(data.family);
                setData(data);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions}/>
            <p>Aqui va el navbar</p>
            <section className="container-customer d-flex justify-content-between mb-4">
                <div className="info-content d-flex align-items-center">
                    <RoundImage src={data.customer_image}/>
                    <div className="info-client mx-3 d-flex align-items-center">
                        {data.customer_fullname}
                        <p>{data.customer_type}</p>
                    </div>
                </div>
                <p>switch</p>
            </section>
            <h4>{t('customer.contactInformation')} La clase del titulo PAULA</h4>
            <section className="bg-card mb-4">
                <InfoClient icon="fa-phone" title={t('customers.phone')} value={data.customer_phone} />
                <InfoClient icon="fa-envelope" title={t('customers.email')} value={data.customer_email} />
            </section>
            <h4>{t('customer.location')} La clase del titulo PAULA</h4>
            <section className="bg-card mb-4">
                <InfoClient icon="fa-earth-americas" title={t('customers.country')} value={data.country_name} />
                <InfoClient icon="fa-flag" title={t('customers.emstateil')} value={data.state_name} />
                <InfoClient icon="fa-city" title={t('customers.city')} value={data.city_name} />
                <InfoClient icon="fa-location-dot" title={t('customers.address')} value={data.customer_address} />
                <InfoClient icon="fa-mailbox" title={t('customers.zipcode')} value={data.customer_zipcode} />
            </section>
            <section className="bg-card mb-4">
                <InfoClient icon="fa-cake-candles" title={t('customers.birthdate')} value={data.customer_birthdate} />
                <InfoClient icon="fa-face-diagonal-mouth" title={t('customers.actitude')} value={data.customer_actitude} />
                <InfoClient icon="fa-calendar" title={t('customers.city')} value={data.created_at} />
            </section>
            <h4>{t('customer.familyAndFriends')} La clase del titulo PAULA</h4>
            {
                family.map( x =>{
                    return <>
                        <img src={x.customer_image} alt="" />
                        <p>{x.customer_fullname}</p>
                        <p>{x.customerknown_relationship}</p>
                    </>
                })
            }
        </div>
    )
}

export default NewCustomersView;