
import { TextField, Autocomplete } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { useForm } from "react-hook-form";
import BarberlyticsFilesUpload from "../../../utils/BarberlyticsFilesUpload";
import SwitchObject from "../../atoms/SwitchObject";
import ColorSelection from "../../molecules/ColorSelection";

const ServicesForm = () => {

    const endpoint = "services";
    const [t,i18n] = useTranslation("global");
    
    const [title,setTitle] = useState(t('servicesComponent.createService'));
    const api = useFetch();
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const {id} = useParams();

    const [formData,setFormData] = useState({
        company_id:                     "",
        service_name:                   "",
        service_name_spanish:           "",
        service_description:            "",
        service_image:                  [],
        service_color:                  "",
        service_combo:                  0,
        servicecategory_id:             "",
        service_duration:               "",
        service_preparation_time:       "",
        service_processing_time:        "",
        service_price:                  "",
        taxgroup_id:                    "",
        service_accepts_gift_cards:     0,
    });

    const [images, setImages] = useState([]); 
    const handleImages = (e) => {
        setImages(e)
    }
    const [categories, setCategories] = useState([]);
    const [taxes, setTaxes] = useState([]);

    const {register, handleSubmit, formState : {errors}, setValue} = useForm();


    // Permisos
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const handleDependencies = (data)=>{
        let d = filterData(data,formData);
        setFormData(d);
    }

    const filterData = (data,formData)=>{
        let finalData = {};
        for(let key in formData){
            finalData[key] = data[key];
            setValue(key,data[key]);
        }
        return finalData;
    }

    const sendRequest = ()=>{
        api.get(endpoint+"/"+id,)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                handleDependencies(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    }

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint,{
            action:"getParamsUpdate"
        })
        .then(response =>{
            if(response.success){
                setCategories(handlerApp.getResultFromObject(response.data,'servicecategories'));
                setTaxes(handlerApp.getResultFromObject(response.data, 'taxes'));
                if(id !== undefined){
                    setTitle(t('servicesComponent.updateService'));
                    sendRequest();
                }else{
                    handlerApp.setLoader(false);
                }
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    },[])

    const handleForm = (e,name=null,dependency=null)=>{
        let key = (name!==null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if(dependency !== null){
            dependency(e.target.value);
        }
    }
    
    const handleCancel = (e)=>{
        e.preventDefault();
        nav(-1);
    }

    // save data
    // save data
    const handleSubmitForm = (data)=>{
        let body = {
            ...data,
            service_combo: formData.service_combo,
            service_name_spanish : data.service_name, 
            service_image: images,
            service_accepts_gift_cards: formData.service_accepts_gift_cards,
            service_color: formData.service_color
        } 
        handlerApp.setLoader(true);
        api.post(endpoint,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data)=>{
        let body = {
            ...data,
            service_image: images,
            service_color: formData.service_color
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/"+endpoint);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCheck = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }
    const handleColor= (value) =>{
        setFormData({
            ...formData, 
            service_color: value
        })
    }

return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>{t("cancel")}</p>
                </div>
            </div>
            <div className="section-form">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <div className="circle-image">
                                    <BarberlyticsFilesUpload  returnImages={handleImages} multiple={false} value={formData.service_image}/>
                                </div>
                            </div>
                            {app.language === "EN" ?  
                                <div className="col-md-5 col-11 mb-2 ">
                                    <TextField label={t("englishName")} variant="outlined" size="small" className="barberlytics-textfield"
                                        {...register("service_name",{ required: true, maxLenght: 40, minLenght: 3})}
                                        value={formData.service_name}
                                        onChange={handleForm}
                                        error={errors.service_name!= null}
                                        helperText = {
                                            errors.service_name?.type === 'required' ? t("errors.required") : 
                                            errors.service_name?.type === 'maxLenght' ? t("errors.maxLenght") : 
                                            errors.service_name?.type === 'minLenght' ? t("errors.minLenght") : "" 
                                        }
                                    />
                                </div>
                            :
                                <div className="col-md-5 col-11 mb-2">
                                <TextField label={t("spanishName")} variant="outlined" size="small" className="barberlytics-textfield"
                                    {...register("service_name_spanish",{ required: true, maxLenght: 40, minLenght: 3})}
                                    value={formData.service_name_spanish}
                                    onChange={handleForm}
                                    error={errors.service_name_spanish!= null}
                                    helperText = {
                                        errors.service_name_spanish?.type === 'required' ? t("errors.required") : 
                                        errors.service_name_spanish?.type === 'maxLenght' ? t("errors.maxLenght") : 
                                        errors.service_name_spanish?.type === 'minLenght' ? t("errors.minLenght") : "" 
                                    }
                                />
                                </div>
                            }
                            <div className="col-md-1 col-1 mb-2">
                                <ColorSelection name="service_color" value={formData.service_color} onChange={handleColor} />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField label={t("servicesComponent.price")} variant="outlined" size="small" type="number" className="barberlytics-textfield"
                                    {...register("service_price",{ required: true, min: 5 })}
                                    value={formData.service_price}
                                    onChange={handleForm}
                                    error={errors.service_price!= null}
                                    helperText = {
                                        errors.service_price?.type === 'required' ? t("errors.required") : 
                                        errors.service_price?.type === 'min' ? t("errors.min") : ""}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField label={t("servicesComponent.duration")} variant="outlined" size="small" type="number" className="barberlytics-textfield"
                                    {...register("service_duration",{ required: true, min: 1 })}
                                    value={formData.service_duration}
                                    onChange={handleForm}
                                    error={errors.service_duration!= null}
                                    helperText = {
                                        errors.service_duration?.type === 'required' ? t("errors.required") : 
                                        errors.service_duration?.type === 'min' ? t("errors.min") : ""}
                                />
                            </div>

                            <div className="col-md-6 mb-2">
                                <BoldSelectValidated
                                    title={t('categoryComponent.category')} 
                                    value={formData.servicecategory_id} 
                                    name="servicecategory_id" 
                                    onChange={handleForm}
                                    options={categories} 
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>   
                            <div className="col-md-6 mb-2">
                                <TextField label={t("servicesComponent.preparationTime")} variant="outlined" size="small" type="number" className="barberlytics-textfield"
                                    {...register("service_preparation_time",{ required: true, min: 0 })}
                                    value={formData.service_preparation_time}
                                    onChange={handleForm}
                                    error={errors.service_preparation_time!= null}
                                    helperText = {
                                        errors.service_preparation_time?.type === 'required' ? t("errors.required") : 
                                        errors.service_preparation_time?.type === 'min' ? t("errors.min") : ""}
                                />
                                <TextField label={t("servicesComponent.processingTime")} variant="outlined" size="small" type="number" className="barberlytics-textfield"
                                    {...register("service_processing_time",{ required: true, min: 0 })}
                                    value={formData.service_processing_time}
                                    onChange={handleForm}
                                    error={errors.service_processing_time!= null}
                                    helperText = {
                                        errors.service_processing_time?.type === 'required' ? t("errors.required") : 
                                        errors.service_processing_time?.type === 'min' ? t("errors.min") : ""}
                                />
                                <BoldSelectValidated
                                    title={t('taxesComponent.tax_name')} 
                                    value={formData.taxgroup_id} 
                                    name="taxgroup_id" 
                                    onChange={handleForm}
                                    options={taxes} 
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div> 
                            <div className="col-md-6 mb-2">
                                <TextField label={t("servicesComponent.description")} variant="outlined" multiline rows={6} size="small" className="barberlytics-textfield"
                                    {...register("service_description",{ required: true })}
                                    value={formData.service_description}
                                    onChange={handleForm}
                                    error={errors.service_description!= null}
                                    helperText = {
                                        errors.service_description?.type === 'required' ? t("errors.required") : ""  
                                    }
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <SwitchObject 
                                    label={t('servicesComponent.acceptGiftCards')}
                                    value={formData.service_accepts_gift_cards} 
                                    name="service_accepts_gift_cards"
                                    onChange={handleCheck}
                                    id={"service_accepts_gift_cards"}
                                />
                            </div>
                            <div className="row d-flex justify-content-end">
                                <div className="d-flex justify-content-end me-5">
                                    {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('submit')}</button>}
                                    {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                                </div>
                            </div>
                        </div>
                        {/* <button onClick={(e)=>{e.preventDefault(); console.log(formData)}}>FormData</button> */}
                </form>
            </div>
        </div>
    )
}

export default ServicesForm;