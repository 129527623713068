import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import BoldUploadImage from "../../Migrar/BoldUploadImage/BoldUploadImage";

const LocationsForm = () => {
    const endpoint = "companies/locations";
    const parent = "Company";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('locationComponent.createLocation');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();
    const [images, setImages] = useState([]);

    // parametros
    const [companies, setCompanies] = useState([]);
    const [managers, setManagers] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    // values
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");


    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        location_name: "",
        company_id: "",
        location_manager: "",
        country_code: "",
        state_code: "",
        city_code: "",
        location_address: "",
        location_zipcode: "",
        location_phone:"",
        location_description:"",
        language_id: "",
        currency_id: ""
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()


    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
        .then(response => {
            if (response.success) {
                setCompanies(handlerApp.getResultFromObject(response.data, 'companies'))
                setManagers(handlerApp.getResultFromObject(response.data, "managers"))
                setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                setCurrencies(handlerApp.getResultFromObject(response.data, 'currencies'));
                setLanguages(handlerApp.getResultFromObject(response.data, 'languages'));
                if (id !== undefined) {
                    setTitle('locationComponent.updateLocation')
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }, [])

    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        console.log(data);
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    // dependencias
    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])

    const getState = () => {
        if (country !== undefined && country !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getStates",
                country: country
            })
            .then(response => {
                if (response.success) {
                    setStates(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                    handlerApp.handlerResponse(response);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(true);
            })
        }
    }

    const getCities = () => {
        if (state !== undefined && state !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getCities",
                country: country,
                state: state
            })
            .then(response => {
                if (response.success) {
                    setCities(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
        }
    }

    return (
        <div className="app container">
           <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3 ms-4">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>Cancel</p>
                </div>
            </div>
            <div className="section-forms-location ms-4">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <section className="formularios row">
                        <div className="col-md-12 mb-4">
                            {/* <BoldUploadImage value={"https://png.pngtree.com/background/20230612/original/pngtree-wolf-animals-images-wallpaper-for-pc-384x480-picture-image_3180467.jpg"} /> */}            
                            <BoldUploadImage handleImages={handleImages} value={""} icon="storefront" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("locationComponent.locationName")} variant="outlined" size="small"
                                {...register("location_name", { required: true, maxLength: 80 })}
                                value={formData.location_name}
                                onChange={handleForm}
                                error={errors.location_name != null}
                                helperText={
                                    errors.location_name?.type === 'required' ? t("errors.required") :
                                    errors.location_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('companyComponent.company')}
                                value={formData.company_id}
                                name="company_id"
                                onChange={handleForm}
                                options={companies}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>


                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.manager')}
                                value={formData.location_manager}
                                name="location_manager"
                                onChange={handleForm}
                                options={managers}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.currency')}
                                value={formData.currency_id}
                                name="currency_id"
                                onChange={handleForm}
                                options={currencies}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.language')}
                                value={formData.language_id}
                                name="language_id"
                                onChange={handleForm}
                                options={languages}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('country')}
                                value={formData.country_code}
                                name="country_code"
                                onChange={handleForm}
                                options={countries}
                                register={register}
                                errors={errors}
                                required={true}
                                dependency={handleSelectCountry}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('state')}
                                value={formData.state_code}
                                name="state_code"
                                onChange={handleForm}
                                options={states}
                                register={register}
                                errors={errors}
                                required={true}
                                dependency={handleSelectState}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('city')}
                                value={formData.city_code}
                                name="city_code"
                                onChange={handleForm}
                                options={cities}
                                register={register}
                                errors={errors}
                                required={true}
                                dependency={handleSelectCity}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("address")} variant="outlined" size="small"
                                {...register("location_address", {  maxLength: 120 })}
                                value={formData.location_address}
                                onChange={handleForm}
                                error={errors.location_address != null}
                                helperText={
                                    errors.location_address?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                    
                    
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("phone")} variant="outlined" size="small"
                                {...register("location_phone", {  maxLength: 15 })}
                                value={formData.location_phone}
                                onChange={handleForm}
                                error={errors.location_phone != null}
                                helperText={
                                    errors.location_phone?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("zipCode")} variant="outlined" size="small"
                                {...register("location_zipcode", {  maxLength: 8 })}
                                value={formData.location_zipcode}
                                onChange={handleForm}
                                error={errors.location_zipcode != null}
                                helperText={
                                    errors.location_zipcode?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="bg-white" label={t("taxesComponent.description")} variant="outlined" multiline rows={3}
                                {...register("location_description", { maxLength: 300 })}
                                value={formData.location_description}
                                onChange={handleForm}
                                error={errors.location_description != null}
                                helperText={
                                    errors.location_description?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                    </section>
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end me-5">

                            {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('create')}</button>}
                            {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                        </div>
                     </div>
                    
                </form>
            </div>
        </div>
        
    )
}

export default LocationsForm;