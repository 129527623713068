import React from 'react';
import "./Navbar.css"
import { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";




const Navbar = (props) => {

    const [data, setData] = useState({
        id:         props?.id,
        user_id:    props?.user_id
    });

    const endpoint = "customer";
    const parent = "Customers";
    const [t] = useTranslation("global");
    const title = t('customers.customers');
    const prefix = "customer";
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        type: []
    });


    useEffect(()=>{
        let id = null;
        let user_id = null;
        if(props.id !== undefined && props.id !== ''){
            localStorage.setItem('navbar-id',props.id);
            id = props.id;
        }else{
            id = localStorage.getItem('navbar-id');
        }

        if(props.user_id !== undefined && props.user_id !== ''){
            localStorage.setItem('navbar-user_id',props.user_id);
            user_id = props.user_id
        }else{
            user_id = localStorage.getItem('navbar-user_id');
        }

        setData({
            ...data, 
            id: id, 
            user_id: user_id
        });
    },[props])

    return (
        
        <div className="menu-contenedor">
            <nav>
                <ul className="main-nav">
                    {/* <li className="main-item">
                        <NavLink exact to="/perspectivas">Perspectivas</NavLink>
                    </li> */}
                    <li className="main-item active">
                        <NavLink exact to="/customer/view/">{t("profile")}</NavLink>
                    </li>
                    <li className="main-item">
                        <NavLink exact to={`/notificaciones/view/${data.user_id}`}>{t("notifications.notifications")}</NavLink>
                    </li>
                    {/*<li className="main-item">
                        <NavLink exact to="/mensajes">Mensajes</NavLink>
                    </li>
                    <li className="main-item">
                        <NavLink exact to="/metodo-de-pago">Método de Pago</NavLink>
                    </li>
                    
                    <li className="main-item">
                        <NavLink exact to="/productos">Productos</NavLink>
                    </li> */}
                </ul>
            </nav>
        </div>
    );
};



export default Navbar;