
import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";
import notFound from "../../../assets/img/image-not-found.webp";

const Products = () => {

    const endpoint = "inventory/products";
    const parent = "Inventory";
    const [t,i18n] = useTranslation("global");
    const title = t('productComponent.products');
    const prefix = "product";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        location: [],
        type: []
    });

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
   
    const columns = [
        {
            id: 'product_id',
            name: 'id',
            selector: row => row.product_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'images',
            name: t('image'),
            cell: (row)=>{
                return (row.images !== null) ? 
                    <img className="bold-image-table" src={row.images[0].image_url} alt={row.product_name} />
                    : <img className="bold-image-table" src={notFound} alt="Image not found" />
            },
            sortable: false,
            width: "90px"
        },
        {
            id: 'product_name',
            name: t('productComponent.product'),
            selector: row => row.product_name,
            sortable: true
        },
        {
            id: 'product_sku',
            name: 'SKU',
            selector: row => row.product_sku,
            sortable: true,
            width: "100px"
        },
        {
            id: 'product_price',
            name: t('productComponent.price'),
            // cell: (row) => {return handlerApp.formatCurrency(row.product_price)},
            selector: row => row.product_price,
            sortable: true,
            width: "100px"
        },
        {
            id: 'product_active',
            name: t('active'),
            selector: (row)=> {return (row.product_active)? t('active') : t("inactive")},
            sortable: true,
            width: "90px"
        },
        {
            name: t('action'),
            cell:(row) => { return handlerApp.actions(row,permissions,'product',handlerAction)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                let dataResponse = response.data;
                dataResponse = dataResponse?.map(e=>{
                    e.product_price = handlerApp.formatCurrency(e.product_price);
                    return e;
                })
                setData(dataResponse);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item?.product_id && 
            (
                item.product_id.toString().includes(filterText.toLowerCase())
                || item.product_name.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.product_sku.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.product_price.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.product_active.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );


    const handleRowSelection = (row)=>{
        console.log(row)
    }

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{t('productComponent.slogan')}</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions(permissions,handlerAction)}
                </div>
                {/* <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div> */}
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("levelComponent.listLevel")}</h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("storageComponent.location")} name="location" onChange={ e => setFilterText(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("storageComponent.storageType")} name="type" onChange={ e => setFilterText(e.target.value)} value="" options={filters.type}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        { permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        ) }
                        <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                onSelectedRowsChange={handleRowSelection}
                persistTableHead
            />            
        </div>
    )
}

export default Products;