import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { LoaderContext } from "../context/LoaderContext";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const HandlerApp = () => {
    const { app } = useContext(AppContext);
    const { setLoaderInner } = useContext(LoaderContext);
    const [t] = useTranslation("global");
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        setPermissions(app.permissions);
    }, [app])

    const setLoader = (value) => {
        setLoaderInner(value);
    }

    const getLocation = () => {
        return localStorage.getItem('currentLocation');
    }
    const showAlertLocation = () => {
        Swal.fire({
            title: '',
            text: "Por favor seleccione una Sucursal para poder continuar",
            icon: 'warning'
        });
    }

    const getResultFromObject = (data, key) => {
        let variable = JSON.parse((data[0][key]));
        return variable;
    }

    const getResult = (data, key) => {
        let variable = JSON.parse((data[0][key]));
        let arrayAux = [];
        for (let i in variable) {
            let cod = i;
            if (isNaN(parseInt(cod))) { // no es un numero
                arrayAux.push({
                    code: i,
                    name: variable[i]
                })
            } else {
                arrayAux.push({
                    code: parseInt(i),
                    name: variable[i]
                })
            }
        }
        return arrayAux;
    }

    const actions = (row, permissions, prefix, action) => {
        if (permissions) {
            return (
                <>
                    {permissions.indexOf('view') > -1 &&
                        <div>
                            <button className="btn-table-actions" title="view" onClick={(e) => { action(row[prefix + "_id"], 'view') }} id={row.ID}>
                                <span className="material-symbols-outlined">visibility</span>
                            </button>
                        </div>}
                    {permissions.indexOf('update') > -1 &&
                        <div>
                            <button className="btn-table-actions" title="edit" onClick={(e) => { action(row[prefix + "_id"], 'update') }} id={row.ID}>
                                <span className="material-symbols-outlined">edit</span>
                            </button>
                        </div>}
                    {permissions.indexOf('delete') > -1 &&
                        <div>
                            <button className="btn-table-actions" title="delete" onClick={(e) => { action(row[prefix + "_id"], 'delete') }} id={row.ID}>
                                <span className="material-symbols-outlined">delete</span>
                            </button>
                        </div>}
                    {
                        <div>
                            {row[prefix + "_active"] === 1 &&
                                <button className="btn-table-actions" title="active" onClick={(e) => { action(row[prefix + "_id"], 'inactive') }} id={row.ID}>
                                    <span className="material-symbols-outlined active">toggle_on</span>
                                </button>
                            }
                            {row[prefix + "_active"] === 0 &&
                                <button className="btn-table-actions" title="inactive" onClick={(e) => { action(row[prefix + "_id"], 'active') }} id={row.ID}>
                                    <span className="material-symbols-outlined inactive">toggle_off</span>
                                </button>
                            }
                        </div>
                    }
                </>
            )
        }
    }
    const actions2 = (row, permissions, prefix, action) => {
        if (permissions) {
            return (
                <div className="dropdown">
                    <button className="btn btn-light btn-table-options dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {t("actions")}
                    </button>
                    <ul className="dropdown-menu item-table-options">
                        {permissions.indexOf('view') > -1 &&
                            <li className="btn item-table-action" onClick={(e) => { action(row[prefix + "_id"], 'view') }} id={row.ID}><span className="material-symbols-outlined">visibility</span> {t("view")}</li>
                        }
                        {permissions.indexOf('update') > -1 &&
                            <li className="btn item-table-action" onClick={(e) => { action(row[prefix + "_id"], 'update') }} id={row.ID}><span className="material-symbols-outlined">edit</span> {t("update")}</li>
                        }
                        {permissions.indexOf('inactive') > -1 &&
                            <li className="btn item-table-action" onClick={(e) => { action(row[prefix + "_id"], 'inactive') }} id={row.ID}><span className="material-symbols-outlined">person_off</span> {t("Inactive")}</li>
                        }
                        {permissions.indexOf('delete') > -1 &&
                            <li className="btn item-table-action" onClick={(e) => { action(row[prefix + "_id"], 'delete') }} id={row.ID}><span className="material-symbols-outlined">delete</span> {t("delete")}</li>
                        }
                    </ul>
                </div>
            )
        }
    }

    // TODO: deprecado - no usar
    const returnDropDownActions = (permissions, handlerAction) => {
        return (
            <>
                <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Actions
                </button>
                <ul className="dropdown-menu">
                    {
                        permissions?.indexOf("create") !== -1 &&
                        (<li>
                            <button className="btn w-100" onClick={() => { handlerAction(null, 'create') }} title="crear"><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.42188 11.625C11.6719 11.625 13.5 13.4531 13.5 15.7031C13.5 16.1484 13.125 16.5 12.6797 16.5H3.79688C3.35156 16.5 3 16.1484 3 15.7031C3 13.4531 4.80469 11.625 7.05469 11.625H9.42188ZM12.6797 15.75C12.7031 15.75 12.75 15.7266 12.75 15.7031C12.75 13.875 11.25 12.375 9.42188 12.375H7.05469C5.22656 12.375 3.75 13.875 3.75 15.7031C3.75 15.7266 3.77344 15.75 3.79688 15.75H12.6797ZM8.25 10.5C6.58594 10.5 5.25 9.16406 5.25 7.5C5.25 5.85938 6.58594 4.5 8.25 4.5C9.89062 4.5 11.25 5.85938 11.25 7.5C11.25 9.16406 9.89062 10.5 8.25 10.5ZM8.25 5.25C7.00781 5.25 6 6.28125 6 7.5C6 8.74219 7.00781 9.75 8.25 9.75C9.46875 9.75 10.5 8.74219 10.5 7.5C10.5 6.28125 9.46875 5.25 8.25 5.25ZM17.625 9.375C17.8125 9.375 18 9.5625 18 9.75C18 9.96094 17.8125 10.125 17.625 10.125H16.125V11.625C16.125 11.8359 15.9375 12 15.75 12C15.5391 12 15.375 11.8359 15.375 11.625V10.125H13.875C13.6641 10.125 13.5 9.96094 13.5 9.75C13.5 9.5625 13.6641 9.375 13.875 9.375H15.375V7.875C15.375 7.6875 15.5391 7.5 15.75 7.5C15.9375 7.5 16.125 7.6875 16.125 7.875V9.375H17.625Z" fill="#37465A" />
                            </svg>
                                Crear
                            </button>
                            {/* <Link to="#" className="dropdown-item actions-link" onClick={()=>{handlerAction(null,'create')}}>
                                Crear
                            </Link> */}
                        </li>
                        )
                    }
                </ul>
            </>
        )


    }

    const handlerResponse = (response) => {
        Swal.fire({
            title: '',
            text: t(response.message),
            icon: 'warning'
        });
    }

    const showOk = (response) => {
        console.log(response);
        Swal.fire({
            title: '',
            text: t(response.message),
            icon: 'success'
        });
    }
    const showError = (msg = null) => {
        Swal.fire({
            title: '',
            text: (msg === null) ? 'An error has ocurred' : msg,
            icon: 'error'
        })
    }

    const getPermissions = (value) => {
        console.log(permissions);
        // console.log(value);
        // console.log(app.permissions);
        // console.log(app.permissions[value]);
        // if(app.permissions !== undefined && app.permissions.hasOwnProperty(value)){
        //     console.log(app.permissions[value]);
        //     return app.permissions[value]
        // }else{
        //     return {};
        // }
        return [];
    }

    const getDate = (date) => {
        if (date != null) {
            let localTime = new Date(date);
            return localTime.toLocaleString("en-US", { hour12: true, year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });
        }
        return "";
    }

    const formatCurrency = (value) => {
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }
        let dollarString = new Intl.NumberFormat("en-US", formatting_options);
        return dollarString.format(value);
    }
    const formatPercent = (value) => {
        let formatting_options = {
            style: 'percent',
        }
        let num = new Intl.NumberFormat("en-US", formatting_options);
        return num.format(value / 100);
    }

    const encode = (str) => {
        // const encoder = new TextEncoder();
        // let encoded = encoder.btoa(encoder.btoa(str));
        let encoded = btoa(btoa(str));
        return encoded;
    }

    const decode = (str) => {
        // const encoder = new TextEncoder();
        // let decoded = encoder.atob(encoder.atob(str));
        let decoded = atob((atob(str)));
        return decoded;
    }

    const getUser = () => {
        if (localStorage.getItem('numberInformation') !== null) {
            return decode(localStorage.getItem('numberInformation'))
        }
        return "";
    }

    const filterData = (data, formData) => {
        let finalData = {};
        // TODO: Revisar logica para filtrar por tipo de dato pasado en forma de arreglo.
        if (!Array.isArray(data)) {
            for (let key in formData) {
                // finalData[key] = data[key];
                let llave = formData[key];
                switch (typeof llave) {
                    case 'number':
                        finalData[key] = parseInt(data[key]);
                        break;
                    case 'string':
                        finalData[key] = data[key];
                        break;
                    case 'boolean':
                        finalData[key] = data[key];
                        break;
                    case 'object':
                        if (llave == null) { // object null
                            finalData[key] = data[key] ? data[key] : {};
                        } else if (Array.isArray(llave)) { // object []
                            finalData[key] = data[key] ? data[key] : [];
                        } else { // object {}
                            finalData[key] = {};
                        }
                        break;
                    default:
                        finalData[key] = "";
                        break;
                }

            }
            return finalData;
        } else {
            for (let key in formData) {
                let llave = formData[key];
                switch (typeof llave) {
                    case 'number':
                        finalData[key] = 0;
                        break;
                    case 'string':
                        finalData[key] = "";
                        break;
                    case 'boolean':
                        finalData[key] = false;
                        break;
                    case 'object':
                        if (llave == null) { // object null
                            finalData[key] = null;
                        } else if (Array.isArray(llave)) { // object []
                            finalData[key] = [];
                        } else { // object {}
                            finalData[key] = {};
                        }
                        break;
                    default:
                        finalData[key] = "";
                        break;
                }
            }
            return finalData;
        }
    }

    const getFiltersFromData = (data, key) => {
        let filter = [];
        data.forEach(element => {
            if (!filter.includes(element[key])) {
                filter.push(element[key]);
            }
        });
        let finalFilter = [];
        filter.forEach(e => {
            if (e != null) {
                finalFilter.push({ code: e, name: e })
            }
        });
        return finalFilter;
    }

    const ChipCustomer = (option) => {
        switch (option) {
            case 'New client':
                return <div className="chips new-client"> {option} </div>;
                break;
            case 'New client retention':
                return <div className="chips new-client"> {option} </div>;
                break;
            case 'Repeat client retention':
                return <div className="chips repeat-client"> {option} </div>;
                break;
            case 'Pre churn':
                return <div className="chips pre-churn"> {option} </div>;
                break;
            case 'Churn':
                return <div className="chips type-churn"> {option} </div>;
                break;
            case 'Recovered':
                return <div className="chips type-recovered"> {option} </div>;
                break;
            default:
                return <div className="chips type-default"> {option} </div>;
                break;
        }
    };

    const ChipActive = (option) => {

        if (option === 1) {
            return <div className="label-status active-status">{t("active")}</div>;
        } else {
            return <div className="label-status inactive-status">{t("inactive")}</div>
        }

    }

    const borderColumn = (row) => {
        return [
            {
                when: row => true,
                classNames: ["col-border"]
                // style: {
                //     borderRight: "1px solid var(--dark-neutral-gray-2)",
                // },
            }
        ];
    }

    const DayOfWeek = (option) => {
        switch (option) {
            case 1:
                return t('schedule.monday');
            case 2:
                return t('schedule.tuesday');
            case 3:
                return t('schedule.wednesday');
            case 4:
                return t('schedule.thursday');
            case 5:
                return t('schedule.friday');
            case 6:
                return t('schedule.saturday');
            case 7:
                return t('schedule.sunday');
        }
    }

    const DayOfWeekNumber = (option) => {
        switch (option) {
            case "Monday":
                return 1
            case "Tuesday":
                return 2;
            case "Wednesday":
                return 3;
            case "Thursday":
                return 4;
            case "Friday":
                return 5;
            case "Saturday":
                return 6;
            case "Sunday":
                return 7;
        }
    }


    const nextDay = (date,setDate) => {
        setDate(moment(date).add(1, 'day'));
    }

    const backDay = (date,setDate) => {
        setDate(moment(date).add(-1, 'day'));
    }


    return {
        setLoader,
        getPermissions,
        actions,
        actions2,
        getResult, getResultFromObject,
        handlerResponse,
        showError,
        showOk,
        getDate,
        formatCurrency,
        formatPercent,
        encode,
        decode,
        getLocation,
        showAlertLocation,
        getUser,
        filterData,
        getFiltersFromData,
        returnDropDownActions,
        ChipCustomer,
        ChipActive,
        borderColumn,
        DayOfWeek,
        DayOfWeekNumber,
        nextDay,
        backDay
    }
}

export default HandlerApp;