
import { TextField, Alert } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm } from "react-hook-form";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import moment from "moment/moment";
import { Navbar } from "react-bootstrap";
import NavbarEdit from "../Customers/NavbarEdit";


const CustomersForms = () => {
    const endpoint = "customer";
    const parent = "customer";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('customers.CreateCustomer');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const { app } = useContext(AppContext);
    const { id } = useParams();

    // parametros
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [typeCustomer, setTypeCustomer] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [birthdate, setBirthdate] = useState("");
    // values
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");

    useEffect(() => {

        if (birthdate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, customer_birthdate: birthdate?.format('YYYY-MM-DD') });
        }
    }, [birthdate]);

    // formulario
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_id: "",
        customer_name: "",
        customer_lastname: "",
        customer_phone: "",
        customer_email: "",
        customer_birthdate: null,
        country_code: "",
        state_code: "",
        city_code: "",
        customer_address: "",
        customer_zipcode: "",
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    // permisos
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])



    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setCompanies(handlerApp.getResultFromObject(response.data, 'companies'))
                    setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                    if (id !== undefined) {
                        setTitle('customers.updateCustomer')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])

    const handleDependencies = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let d = filterData(data, formData);
        if (d.customer_birthdate != null) {
            setBirthdate(moment(d.customer_birthdate));
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }



    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
      
        data.customer_birthdate = birthdate.format('YYYY-MM-DD') 
        api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
       
        handlerApp.setLoader(true);
        data.customer_birthdate = birthdate.format('YYYY-MM-DD') 

        console.log(data.customer_birthdate);
        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    // dependencias
    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])

    const getState = () => {
        if (country !== undefined && country !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getStates",
                country: country
            })
                .then(response => {
                    if (response.success) {
                        setStates(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.setLoader(false);
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(true);
                })
        }
    }

    const getCities = () => {
        if (state !== undefined && state !== "") {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getCities",
                country: country,
                state: state
            })
                .then(response => {
                    if (response.success) {
                        setCities(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch(error => {
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(false);
                })
        }
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page ps-3">{t(title)}</h3>
                </section>
                <div className="d-flex">
                    <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={handleCancel}>Cancel</p>
                </div>
            </div>
            <NavbarEdit />
            <div className="container bold-container-content">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("name")} variant="outlined" size="small"
                                {...register("customer_name", { required: true, maxLength: 40 })}
                                value={formData.customer_name}
                                onChange={handleForm}
                                error={errors.customer_name != null}
                                helperText={
                                    errors.customer_name?.type === 'required' ? t("errors.required") :
                                        errors.customer_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("lastName")} variant="outlined" size="small"
                                {...register("customer_lastname", { required: true, maxLength: 40, })}
                                value={formData.customer_lastname}
                                onChange={handleForm}
                                error={errors.customer_lastname != null}
                                helperText={
                                    errors.customer_lastname?.type === 'required' ? t("errors.required") :
                                        errors.customer_lastname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("phone")} variant="outlined" size="small"
                                {...register("customer_phone", { required: true, maxLength: 16 })}
                                value={formData.customer_phone}
                                onChange={handleForm}
                                error={errors.customer_phone != null}
                                helperText={
                                    errors.customer_phone?.type === 'required' ? t("errors.required") :
                                        errors.customer_phone?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("email")} variant="outlined" size="small"
                                {...register("customer_email", {
                                    required: true,
                                    maxLength: 80,
                                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/
                                })}
                                value={formData.customer_email}
                                onChange={handleForm}
                                error={errors.customer_email != null}
                                helperText={
                                    errors.customer_email?.type === 'pattern' ? t("errors.pattern") : ""}
                            />

                        </div>
                        <div className="col-md-6 mb-2">
                            <DatePicker
                                className="text-view"
                                label={t('birthdate')}
                                format="YYYY/MM/DD"
                                name="customer_birthdate"
                                variant="outlined"
                                onChange={(val) => { setBirthdate(val) }}
                                value={birthdate}
                                slotProps={{ textField: { size: 'small', variant: "outlined" } }}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("address")} variant="outlined" size="small"
                                {...register("customer_address", { required: true, maxLength: 40 })}
                                value={formData.customer_address}
                                onChange={handleForm}
                                error={errors.customer_address != null}
                                helperText={
                                    errors.customer_address?.type === 'required' ? t("errors.required") :
                                        errors.customer_address?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField className="text-view" label={t("zipCode")} variant="outlined" size="small"
                                {...register("customer_zipcode", { required: false, maxLength: 8 })}
                                value={formData.customer_zipcode}
                                onChange={handleForm}
                                error={errors.customer_zipcode != null}
                                helperText={
                                    errors.customer_zipcode?.type === 'required' ? t("errors.required") :
                                        errors.customer_zipcode?.type === 'maxLength' ? t("errors.maxLength") : ""}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('companyComponent.company')}
                                value={formData.company_id}
                                name="company_id"
                                onChange={handleForm}
                                options={companies}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('country')}
                                value={formData.country_code}
                                name="country_code"
                                onChange={handleForm}
                                dependency={handleSelectCountry}
                                options={countries}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('state')}
                                value={formData.state_code}
                                name="state_code"
                                onChange={handleForm}
                                dependency={handleSelectState}
                                options={states}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('city')}
                                value={formData.city_code}
                                name="city_code"
                                onChange={handleForm}
                                dependency={handleSelectCity}
                                options={cities}
                                register={register}
                                errors={errors}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className="contai-checkbox">
                        <input type="checkbox" id="mi-checkbox" />
                        <label htmlFor="mi-checkbox" className="custom-checkbox"></label><p className="flex">Reserve & payment online only</p>
                    </div>
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end me-5">

                            {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('submit')}</button>}
                            {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
                        </div>
                    </div>
                </form>

            </div>

        </div>
    )
}

export default CustomersForms;