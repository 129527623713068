import "./RoundImage.css";

const RoundImage = ({src})=>{
    return(
        <>
            { 
                (src !== null) &&
                <section className="round-image-container" style={{backgroundImage: `url('${src}')`}}>
                </section>
            }
        </>
    )
}
export default RoundImage;