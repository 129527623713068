import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';
import Navbar from './Navbar';
import "./Navbar.css"
import moment from "moment/moment";

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import Notes from "./Notes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomersView = () => {
    const endpoint = "customer";
    const [t, i18n] = useTranslation("global");
    const prefix = "category";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    setTitle(data.customer_fullname);
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const back = () => {
        nav(-1);
    }

    const maksNumber = (phone) => {
        console.log("phone");
        console.log(phone);
        const lastFourDigits = phone?.substring(phone?.length - 4);

        // Reemplazar los dígitos anteriores por asteriscos
        const hiddenDigits = "*".repeat(phone?.length - 4);

        // Número de teléfono oculto
        return hiddenDigits + lastFourDigits;
    }

    console.log(data);

    return (



        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{title}</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
                <Navbar />
                <div className="title-section-forms">
                </div>
                <div className="row np-row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("name")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_fullname}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('companyComponent.company')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.company_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('phone')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>

                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('email')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('birthdate')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_birthdate}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('address')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_address}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('zipCode')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_zipcode}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('customers.type')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_type}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('customers.reputation')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_reputation}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('customers.origin')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_origin}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('country')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.country_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('state')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.state_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}


                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('city')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.city_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('comments')} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_comments}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="contai-checkbox">
                                <input type="checkbox" id="mi-checkbox" />
                                <label htmlFor="mi-checkbox" className="custom-checkbox"></label><p className="flex">Reserve & payment method</p>
                            </div>
                        </div>
                    </div>

                    <Notes id={id} notes={data.customer_notes} />

                </div>



                <div className="footer-section-view row">
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomersView;