import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { TextField } from "@mui/material";



const LocationsView = ()=>{
    const endpoint = "companies/locations";
    const [t,i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [location,setLocation] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();
    const [images, setImages] = useState([]);


    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.location_name);
                setLocation(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }
    const handleImages = (e) => {
        setImages(e)
    }

    return(
        <div className="app container">
            <div className="d-flex justify-content-between ml=30px">
                <section className="section-title-page ms-4">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{t(title)}</h3>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            <div className="row d-flex justify-content-evenly">
                <div className="card2">
                    <p className="title-page">
                    </p>
                </div>
                <div className="card2">
                    <p className="title-page">
                    </p>
                </div>
                <div className="card2">
                    <p className="title-page">
                    </p>
                </div>
            </div>
            <div className="card">
                <p className="title-page">
                    <span class="material-symbols-outlined iconSize">Storefront</span>
                </p>
            </div>
            <div className="container bold-container-content">
            <section className="formularios">
                        <div className="column col-md-4">
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("name")} variant="outlined" size="small"
                                    value={location.location_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("country")} variant="outlined" size="small"
                                    value={location.country_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("state")} variant="outlined" size="small"
                                    value={location.state_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("city")} variant="outlined" size="small"
                                    value={location.city_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("address")} variant="outlined" size="small"
                                    value={location.location_address}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                        </div>
                        <div className="column col-md-4">
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("phone")} variant="outlined" size="small"
                                    value={location.location_phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                                
                            <div className="col-md-12 mb-2">
                                <TextField className="text-view" label={t("zipCode")}  size="small"
                                    value={location.location_zipcode}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            
                            <div className="col-md-12">
                                <TextField className="bg-white" label={t("taxesComponent.description")} variant="outlined" multiline rows={5.7}
                                    value={location.location_description}
                                />
                            </div>
                            

                            <div className="row d-flex justify-content-center">
                                
                            </div>
                        </div>
                    </section>
                {/* <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.location')}:</dt>
                        <dd>{location.location_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('companyComponent.company')}</dt>
                        <dd>{location.company_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.manager')}</dt>
                        <dd>{location.manager}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('country')}</dt>
                        <dd>{location.country_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('state')}</dt>
                        <dd>{location.state_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('city')}</dt>
                        <dd>{location.city_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('address')}</dt>
                        <dd>{location.location_address}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('zipCode')}</dt>
                        <dd>{location.location_zipcode}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.currency')}</dt>
                        <dd>{location.currency_short_name}</dd>
                    </div>
                </div> */}
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {location.location_created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(location.location_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {location.location_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {location.location_modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {location.location_modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(location.location_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default LocationsView;