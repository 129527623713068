

const InfoClient = ({icon, title, value})=>{
    return(
        <section className="info-client d-flex align-items-center">
            <i className={`fa-regular ${icon}`}></i>
            <div className="mx-3">
                <dt>{title}</dt>
                <dd>{value}</dd>
            </div>
        </section>
    )
}
export default InfoClient;